<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Work Orders</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'workOrderForm', params: { id: 0 } }"
                        >
                            New Work Order
                        </v-btn>
                    </template>
                    <span>Work Order</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <!-- <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Category</label>
                <v-combobox
                    v-model="filter.categorySelected"
                    :items="listCategory"
                    item-text="description"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col> -->
            <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Type</label>
                <v-combobox
                    v-model="filter.typeSelected"
                    :items="listType"
                    item-text="description"
                    item-value="value"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters(); filter.responsibleSelected = null;"
                ></v-combobox>
            </v-col>
            <v-col 
                v-if="showExternalResponsible == false"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <label>Responsible</label>
                <v-combobox
                    v-model="filter.responsibleSelected"
                    :items="listUser"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                v-else
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <label>Responsible</label>
                <v-combobox
                    v-model="filter.responsibleSelected"
                    :items="listContractor"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="6"
                lg="3"
                md="6"
                sm="6"
            >
                <v-menu
                    ref="mnuTargetDateStart"
                    v-model="mnuTargetDateStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>Target Date Start</label>
                        <v-text-field
                            v-model="filter.targetDateStartFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.targetDateStart"
                        no-title
                        @input="mnuTargetDateStart = false"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col 
                cols="6"
                lg="3"
                md="6"
                sm="6"
            >
                <v-menu
                    ref="mnuTargetDateEnd"
                    v-model="filter.mnuTargetDateEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>Target Date End</label>
                        <v-text-field
                            v-model="filter.targetDateEndFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.targetDateEnd"
                        no-title
                        @input="mnuTargetDateEnd = false"
                        :min="filter.targetDateStart"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.targetDateFormatted="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.targetDateFormatted }}
                        </label>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'workOrderForm'"
                            :showButtons="{
                                edit: item.status === 0 ? true : false,
                                delete: false,
                                cancel2: item.status === 0 ? true : false,
                                markStarted: checkEnableStart(item),
                                markDoneUnDone: item.status === 1 ? true : false,
                                markFailed: item.status === 1 ? true : false,
                                hold: item.status == 0 || item.status == 1 || item.status == 3 ? true : false,
                                resume: item.status === 5 ? true : false,
                                download: item.status !== 4 ? true : false
                            }"
                            @confirmCancel2="confirmCancel"
                            @confirmMarkAsStarted="confirmMarkAsStarted"
                            @confirmMarkAsFailed="confirmMarkAsFailed"
                            @confirmDoneUnDone="confirmDoneUnDone"
                            @confirmHold="confirmHold"
                            @confirmResume="confirmResume"
                            @confirmDownload="confirmDownload"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <v-dialog
            v-model="showDialogDate"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ titleDate }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row v-if="typeSchedule != 'R'">
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Select Date</label>
                            <v-menu
                                ref="mnuTodayDate"
                                v-model="mnuTodayDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="todayDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="todayDate = parseDate(todayDateFormatted)"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="todayDate"
                                    no-title
                                    @input="mnuTodayDate = false"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row v-if="typeSchedule == 'F' || typeSchedule == 'H' || typeSchedule == 'R'">
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Reason</label>
                            <v-textarea
                                v-model="description"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 buttonCancelDialog"
                            style=""
                            outlined
                            @click="showDialogDate = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="confirmDate()"
                        >
                            Confirm
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import * as moment from 'moment';

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: vm => ({

            id: 0,
            statusId: 0,

            loading: false,

            filter: {
                fastSearch: '',
                // categorySelected: null,
                typeSelected: null,
                responsibleSelected: null,
                statusSelected: null,
                targetDateStart: moment().clone().startOf('month').format('YYYY-MM-DD'),
                targetDateEnd: moment().clone().endOf('month').format('YYYY-MM-DD'),
                targetDateStartFormatted: moment().clone().startOf('month').format('MM/DD/YYYY'),
                targetDateEndFormatted: moment().clone().endOf('month').format('MM/DD/YYYY'),
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Project", value: "projectDescription", sortable: true },
                // { text: "Category", value: "categoryWorkOrderDescription", sortable: true },
                { text: "Target Date", value: "targetDateFormatted", sortable: true },
                { text: "Type", value: "typeWorkOrderDescription", sortable: true },
                { text: "Responsible", value: "responsibleName", sortable: true },
                { text: "Submitted", value: "submittedDescription", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listWorkOrder: [],
            listWorkOrderFiltered: [],
            // listCategory: [],
            listType: [],
            listUser: [],
            listContractor: [],
            listStatus: [],

            mnuTargetDateStart: false,
            mnuTargetDateEnd: false,

            titleDate: '',
            todayDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            todayDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            mnuTodayDate: false,

            showDialogDate: false,
            typeSchedule: '',
            description: '',
        }),

        watch: {
            todayDate () {
                this.todayDateFormatted = this.formatDate(this.todayDate)
            },

            "filter.targetDateStart" () {
                this.filter.targetDateStartFormatted = this.formatDate(this.filter.targetDateStart)
                this.filterRegisters();
            },

            "filter.targetDateEnd" () {
                this.filter.targetDateEndFormatted = this.formatDate(this.filter.targetDateEnd)
                this.filterRegisters();
            },
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listWorkOrderFiltered.filter((workOrderFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dateRegisterFormatted = workOrderFilter.dateRegisterFormatted.toLowerCase().match(filter)
                    var projectDescription = workOrderFilter.projectDescription.toLowerCase().match(filter)
                    var userNameRegister = workOrderFilter.userNameRegister.toLowerCase().match(filter)
                    // var categoryWorkOrderDescription = workOrderFilter.categoryWorkOrderDescription.toLowerCase().match(filter)
                    var targetDateFormatted = workOrderFilter.targetDateFormatted.toLowerCase().match(filter)
                    var typeWorkOrderDescription = workOrderFilter.typeWorkOrderDescription.toLowerCase().match(filter)
                    var responsibleName = workOrderFilter.responsibleName.toLowerCase().match(filter)
                    var id = workOrderFilter.id.toString().match(filter);

                    if(dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(projectDescription != null) { return projectDescription; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    // else if(categoryWorkOrderDescription != null) { return categoryWorkOrderDescription; } 
                    else if(targetDateFormatted != null) { return targetDateFormatted; } 
                    else if(typeWorkOrderDescription != null) { return typeWorkOrderDescription; } 
                    else if(responsibleName != null) { return responsibleName; } 
                    else { return id; }
                });
            },

            showExternalResponsible() {
                return this.filter.typeSelected != null &&
                       this.filter.typeSelected != undefined &&
                       this.filter.typeSelected.id == 2
            },
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__cinza_escuro)';
                        break;

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                    case 4:
                        color = 'var(--color__red)';
                        break;

                    case 5:
                        color = 'var(--color__pipeline_waiting_approval)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async getRegisters() {

                this.listWorkOrder = await this.$store.dispatch("workOrderModule/List");
                this.listWorkOrderFiltered = [...this.listWorkOrder];
                
                this.listStatus = await this.$store.dispatch("workOrderModule/ListStatus");
                // this.listCategory = await this.$store.dispatch("workOrderModule/ListCategory");
                this.listType = await this.$store.dispatch("workOrderModule/ListType");
                this.listUser = await this.$store.dispatch("userModule/List");
                this.listContractor = await this.$store.dispatch("contractorModule/List");
                this.filterRegisters();
            },

            confirmCancel(id) {

                this.statusId = 4;
                this.id = id;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will CANCEL this Work Order, confirm your decision?',
                    methodConfirm: this.updateStatus,
                    params: id
                };

            },

            confirmMarkAsStarted(id) {
                this.description = '';
                this.titleDate = 'Mark as Started'
                this.statusId = 1;
                this.typeSchedule = "S";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmMarkAsFailed(id) {
                this.description = '';
                this.titleDate = 'Mark as Incomplete'
                this.statusId = 3;
                this.typeSchedule = "F";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmDoneUnDone(id) {
                this.description = '';
                this.titleDate = 'Mark as Done'
                this.statusId = 2;
                this.typeSchedule = "C";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmHold(id) {
                this.description = '';
                this.titleDate = 'Hold'
                this.statusId = 5;
                this.typeSchedule = "H";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmResume(id) {
                this.description = '';
                this.titleDate = 'Resume'
                this.statusId = 6;
                this.typeSchedule = "R";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmDate() {

                let textConfirmation = '';
                let valid = true;

                if (this.typeSchedule == "S") {
                    textConfirmation = 'START'
                }
                else if (this.typeSchedule == "C") {
                    textConfirmation = 'FINISH'
                }
                else if (this.typeSchedule == "F") {
                    textConfirmation = 'mark as INCOMPLETE'
                }
                else if (this.typeSchedule == "H") {
                    textConfirmation = 'mark as ON HOLD'
                }
                else if (this.typeSchedule == "R") {
                    textConfirmation = 'RESUME'
                }

                if (this.typeSchedule == "F") {
                    if (this.description.toString().trim() == "") {
                        valid = false;
                        this.showToast("error", "Error!", "Reason is required for this action!");
                    }
                }

                if (valid == true) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `You will ${textConfirmation} this Work Order, confirm your decision?`,
                        methodConfirm: this.updateStatus,
                        params: this.id
                    };
                }
            },

            async updateStatus() {

                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: this.statusId,
                    date: this.todayDate,
                    description: this.typeSchedule == "F" || this.typeSchedule == "H" || this.typeSchedule == "R" ? this.description : null
                }

                let result = await this.$store.dispatch("workOrderModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();
                this.showDialogDate = false;

                this.hideLoading();
            },

            async confirmDownload(id) {

                this.showLoading();

                this.$vanKirkApi.get(`workOrder/download/${id}`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.getRegisters();
                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Error to generate work order: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Error to generate work order: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            async filterRegisters() {

                // let idCategory = 0;
                let idType = 0;
                let idResponsible = 0;
                let idStatus = 0;
                let listFiltered = [...this.listWorkOrder];

                // if (this.filter.categorySelected != null && this.filter.categorySelected != undefined) {
                //     idCategory = this.filter.categorySelected.id;
                //     listFiltered = listFiltered.filter(wor => wor.categoryWorkOrder == idCategory);
                // }

                if (this.filter.typeSelected != null && this.filter.typeSelected != undefined) {
                    idType = this.filter.typeSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.typeWorkOrder == idType);
                }

                if (this.filter.responsibleSelected != null && this.filter.responsibleSelected != undefined) {
                    idResponsible = this.filter.responsibleSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.idInstaller == idResponsible);
                }

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    idStatus = this.filter.statusSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.status == idStatus);
                }

                if (this.filter.targetDateStart != null && this.filter.targetDateStart != undefined && 
                    this.filter.targetDateEnd != null && this.filter.targetDateEnd != undefined) {
                    listFiltered = listFiltered.filter(wor => (wor.targetDate >= this.filter.targetDateStart && wor.targetDate <= this.filter.targetDateEnd) || (wor.targetDate == null || wor.targetDate == undefined));
                }

                this.listWorkOrderFiltered = listFiltered;
            },

            checkEnableStart(item) {
                return item.status === 0 || 
                       (this.userLoggedGetters.profile.rollbackWorkOrderIncomplete == 1 && 
                        (item.status === 2 || item.status === 3)) ? true : false
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>